import React from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../store/AuthContext'
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';


import "react-datepicker/dist/react-datepicker.css";
// import { RiCalendar2Line } from 'react-icons/ri';
import { Link } from "react-router-dom";
import axios from "axios";

var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

registerLocale('fr', fr)

export default function UpdateInfo() {
  const history = useHistory()

  const { authState, authDispatch } = React.useContext(AuthContext);
  const [ step, setStep ] = React.useState(1);
  const [ error, setError ] = React.useState('');
  const [ query, setQuery ] = React.useState('');
  const [ customer, setCustomer ] = React.useState({
    appellation: '',
    firstName: authState.customer.firstName,
    lastName: authState.customer.lastName,
    address: '',
    postcode: '',
    city: '',
    country: '',
    personalIdType: '',
    personalIdNumber: '',
    personalIdDeliveryDate: '',
    personalIdDeliveryFrom: '',
    email: authState.customer.email,
    prestashopId: authState.customer.prestashopId,
    username: authState.customer.email.toLowerCase()
  
  });
  console.log('authState',authState)
  React.useEffect(() => {
    const updateCustomer = () =>{

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/apiIframe/customer/update`,
        headers: { Authorization: "Bearer " + authState.token },
        data: query
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.status) {
          authDispatch({type:'customerAuth', payload: res.data.customer});
          history.push('/checkout')
        }else{
          if(res.data.error){
              setError(res.data.error) 
          }
        }
      })
      .catch((err) => {
        setQuery('')
        console.log("error",err);
      });

    }

    if (query !== '') {
      setError('')
      setQuery('')
      updateCustomer();
    }
}, [query, authDispatch, authState, history])


  return (
<div className='flex-col md:h-full flex items-center'>
    <h3 className="text-2xl pt-5 w-4/5 text-left">
      Mise à jour de vos informations {step}/2
    </h3>
       {step === 1 ?
      <form 
        className='h-full w-4/5 flex flex-col justify-around'
        onSubmit={e => {
            setError('')
            e.preventDefault();
            setStep(2)
        }}>
        
        <div className='py-4 flex flex-col justify-center'>

          <div className="flex flex-col md:flex-row justify-evenly ">
            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Civilité
              </label>
              <select className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={ customer.appellation || ''}
                  onChange={e => setCustomer({...customer, appellation: e.target.value}) }
                  required
              >
                  <option value='' disabled>-</option>
                  <option value='M'>M</option>
                  <option value='Mme'>Mme</option>
                  {/* <option value='Mlle'>Mlle</option> */}
              </select>
            
            </div>

            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Prénom
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Votre prénom"
                onChange={e => setCustomer({...customer, firstName: e.target.value}) }
                value={customer.firstName || ''}
                required
              />
            </div> 
            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Nom
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Votre nom"
                onChange={e => setCustomer({...customer, lastName: e.target.value}) }
                value={customer.lastName || ''}
                required
              />
            </div> 
          </div>

          <div className="flex flex-col md:flex-row justify-evenly">
            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Adresse
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="N° et voie"
                onChange={e => setCustomer({...customer, address: e.target.value}) }
                value={customer.address || ''}
                required
              />
            </div>

            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Code Postal
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="123456"
                onChange={e => setCustomer({...customer, postcode: e.target.value}) }
                value={customer.postcode || ''}
                required
              />
            </div> 
            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Ville
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Votre ville"
                onChange={e => setCustomer({...customer, city: e.target.value}) }
                value={customer.city || ''}
                required
              />
            </div> 
          </div>
          <div className="flex flex-col md:flex-row">
            <div className='md:w-1/3 px-2 mt-4'>
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                Pays
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Pays"
                onChange={e => setCustomer({...customer, country: e.target.value,  isoCode : countries.getAlpha2Code(e.target.value, "fr")}) }
                value={customer.country || ''}
                required
              />
            </div>

        
          </div>
        </div>

        
          

        <span className="self-center text-cartHover">
          {error !== '' ? error : null}
        </span>

        <div className='md:h-2/6 flex flex-col items-center justify-evenly'>
          <button type="submit" className="w-60 my-2 text-center px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white">Valider</button>
          <Link to="/checkout" className="w-60 my-2 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover">Retour</Link>
        </div>

      </form>
       :
       <>

      <form 
        className='h-full w-4/5 flex flex-col justify-evenly '
        onSubmit={e => {
            e.preventDefault()
            setQuery(customer)
        }}>

        <div className=' py-4 flex flex-col justify-center'>

          <div className="flex justify-evenly">
            <div className='md:w-1/2 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Type de document d'identité
              </label>
              <select className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={ customer.personalIdType || ''}
                  onChange={e => setCustomer({...customer, personalIdType: e.target.value}) }
                  required
              >
                  <option value='' disabled>-- Sélectionner un type --</option>
                  <option value='Passport'>Passeport</option>
                  <option value="idCard">Carte d'identité</option>
              </select>
            
            </div>

            <div className='md:w-1/2 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
              Numéro de la pièce d'identité
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder=""
                
                onChange={e => setCustomer({...customer, personalIdNumber: e.target.value}) }
                value={customer.personalIdNumber || ''}
                required
              />
            </div> 
          </div>

          <div className="flex flex-col md:flex-row justify-evenly">

            <div className='md:w-1/2 px-2 mt-4'>
                <label className="block text-sm font-medium text-gray-700">
                Délivrée le
                </label>
                <div className='flex items-center'>

                  <DatePicker
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      locale='fr'
                      dateFormat="dd/MM/yyyy"
                      onChange={date => setCustomer({...customer, personalIdDeliveryDate: date})}
                      selected={customer.personalIdDeliveryDate || ''}
                      required  
                  />
                  {/* <RiCalendar2Line 
                    className='mx-2 text-3xl'
                  /> */}
                </div>
            </div>

            <div className='md:w-1/2 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
              Délivrée par
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder=""
                onChange={e => setCustomer({...customer, personalIdDeliveryFrom: e.target.value}) }
                value={customer.personalIdDeliveryFrom || ''}
                required
              />
            </div> 

          </div>


            <div className='px-2 mt-4'>
                <div className='flex items-center mt-5'>
                  <input type="checkbox" 
                    required/>
                  <label className="mx-2 block text-sm font-medium text-gray-700">
                    J’atteste sur l’honneur que les informations renseignées ci-dessus sont certifiées exactes 
                  </label>
                </div>
          </div>
          <div className='my-2 text-xs font-medium text-gray-700'>
            <div>
            Ammareal en tant que responsable de traitement, collecte vos données personnelles de manière obligatoire afin de procéder au rachat de vos livres d’occasion et de tenir le registre de Police qui constitue une obligation légale concernant ce type d’opération. La base légale de ce traitement est donc le respect d’une obligation légale. Les destinataires des données sont Ammareal et les autorités de contrôle compétentes. Ammareal conserve vos données personnelles pendant une durée de 10 ans à partir de leur collecte.
            </div>
            <br></br>
            <div>
            Conformément au RGPD, vous disposez d’un droit d’accès, de rectification, d’effacement et de limitation sur vos données. Vous pouvez adresser votre demande par courrier électronique à l’adresse dpo@ammareal.fr ou par courrier postal : Ammareal SAS, 31, rue Marcelle Henri – 91200 Athis-Mons.

            </div>
            <div>
            Vous disposez également du droit d’introduire une réclamation auprès de la CNIL.

            </div>
            <div>
            Pour plus d’informations sur la signification des droits et sur l’introduction d’une réclamation, veuillez trouver le site de la CNIL : https:// www.cnil.fr/.

            </div>
          </div>
          
        </div>

        <span className="self-center text-cartHover">
          {error !== '' ? error : null}
        </span>

        <div className='md:h-2/6 flex flex-col items-center justify-evenly'>
          <button 
            type='submit'
            className="w-60 text-center my-2 px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white"
          >Valider</button>
          <button 
            className="w-60 text-center my-2 px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover"
            onClick={() => setStep(1)}
          >Retour</button>
        </div>

      </form>


       
       </>
       }
  </div>    
  );
}