import React from 'react';
import { useHistory } from "react-router-dom";
import { BookContext } from '../store/BookContext'

export default function Book() {
    const history = useHistory()
    const { bookState, bookDispatch } = React.useContext(BookContext);
    const [ action, setAction ] = React.useState(null)
    const [isExistBook, setIsExistBook] = React.useState(false)
    
    React.useEffect(() => {
        const doAction = () => {
            bookDispatch({type: action})
            history.push('/scan')
        }

        if (action !== null) {
            setAction(null)
            doAction()
        }
        if (bookState.book && bookState.cart.filter(e=>e.userChoice==='sold').some(e => e.isbn13 === bookState.book.isbn13 )) { 
            // console.log('Is exist')
            setIsExistBook(true)
        }
      }, [ action, setAction, bookDispatch, history, bookState])

    return (
      <div className="h-full px-6 flex flex-col">

          <div className="h-2/3 flex flex-col md:flex-row items-center justify-between">

            <div className="h-2/3 w-full md:w-1/2 flex items-center justify-evenly">
                {}
                <img 
                    src={bookState.book.image ? bookState.book.image : `${process.env.PUBLIC_URL}/noImage.png` } 
                    alt="book cover"
                    className="h-3/4 object-contain sm:w-full max-w-[50%]"/>
                <div className="h-3/4 p-3 flex flex-col text-sm sm:text-base">
                    <span>{bookState.book.title}</span>
                    <span>{bookState.book.authors ?
                        <p>{' '}
                            {bookState.book.authors.map((author, index) => {
                                let newAuthor = author.replace(',',', ')
                                let displayAuthor
                 
                                if(index+1  < bookState.book.authors.length && index >= 0){
                                    displayAuthor =  <span key={index}>{newAuthor}, </span>
                                }else{
                                    displayAuthor =  <span key={index}>{newAuthor}</span>
                                }
                                
                                return displayAuthor
                            })}
                        </p>
                    :null}</span>
                    <span className='mt-3'>{bookState.book.isbn13}</span>
                </div>
            </div> 
            <div className="md:h-full w-full md:w-1/2 flex flex-col items-center justify-center mt-3">
                { bookState.book.status !== 'accepted' ?
                    <div className='border-2 border-black p-5 text-center'>
                     <span className='font-bold'>Désolé, ce livre ne peut être repris.</span>
                     <br></br>
                     <span>Malgré toutes ses qualités, nous ne saurons pas le valoriser correctement.<br></br>
                            Donnez-le à un ami ou remettez-le à votre recyclerie.
                    </span>
                    </div>
                :
                isExistBook ?
                <div className={"d-flex flex-column "}>
                    <p  className={"text-center"}>
                        Ce livre est déjà dans votre panier.
                    </p>
                    <p>
                        D'après l'article 3.3 de nos CGAU, vous ne pouvez pas proposer plusieurs exemplaires d’un même article dans une même vente.
                    </p>
                </div>
                :
                    <div className='border-2 border-black p-4 whitespace-nowrap '>
                        <div className='flex flex-col justify-center items-center '>Prix de reprise proposé : 
                            <span className="mt-2 text-3xl text-cartHover font-bold ">
                                {bookState.book.price.toString().replace('.', ',')} €
                            </span>
                        </div>
                    </div>
                }
            </div>

          </div>

        <div className="h-1/3 flex flex-col-reverse md:flex-row justify-around items-center">
            { bookState.book.status !== 'accepted' ?
                <button 
                    className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white"
                    onClick={ () => setAction('rejectedBook') }
                >Retour</button>
                
            :
                <>
                    <button 
                        className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover"
                        onClick={ () => setAction('keepBook') }
                    >Refuser</button>
                 { !isExistBook ?    
                    <button 
                        className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white"
                        onClick={ () => setAction('sellBook') }
                    >Accepter</button>
                    :
                    null
                }
                </>
            }
            
        </div>

      </div>
    );
}