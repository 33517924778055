function parseJSON(response) {
  return response.json()
}

function checkStatus(response) {
    // console.log('checkStatus!!',response)
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      var error = new Error(response.status)
      error.response = response
      throw error
    }
  }

export async function fetchScan (isbn){
    return new Promise((resolve, reject)=>{
        fetch(`${process.env.REACT_APP_API_URL}/apiIframe/getPrice`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`
          },
          body: JSON.stringify({
          isbn: isbn,
          appId: "decitre"
          })
        })
        .then(checkStatus)
        .then(parseJSON)
        .then(function(data) {
          console.log('request succeeded with JSON response', data);
          resolve({error:0, data})
        })
        .catch(function(error) {
            // console.log('request failed ', error)
            reject({error:1, errorStatus: error.toString().replace(/\D/g, '')});
            })
    })
}