import React from 'react';

const BookContext = React.createContext();

//Test navigator cookie enable before to try use local or session storage
if(!window.navigator.cookieEnabled){
   alert("Pour fonctionner le service de reprise nécessite l'utilisation de cookies. Rendez-vous dans les paramètres de votre navigateur sous \"Confidentialité et sécurité\" pour autoriser les cookies.")
}

const localState = JSON.parse(sessionStorage.getItem('bookState'));

const initialState = {
    book: null,
    preRefurb: null,
    lastAction: null,
    cart: [],
}

function bookReducer(state, action){
    switch (action.type) {
        case 'reset': {
            sessionStorage.removeItem("bookState")
            return initialState
        }
        case 'currentBook': {
            const updatedBook = action.payload;
            updatedBook.id = Date.now();
            return {
                ...state,
                book: updatedBook
            }
        }
        case 'sellBook': {
            const updatedCart = [...state.cart];
            const updatedBook = {...state.book, 'userChoice': 'sold'}
            updatedCart.push({ ...updatedBook});
            return {
                ...state,
                book: null,
                cart: updatedCart,
                lastAction: 'SELL',
            }
        }
        // case 'giveBook': {
        //     const updatedCart = [...state.cart];
        //     const updatedBook = {...state.book, 'userChoice': 'given'}
        //     updatedCart.push({ ...updatedBook});
        //     return {
        //         ...state,
        //         book: null,
        //         cart: updatedCart,
        //         lastAction: 'GIVE',
        //     }
        // }
        case 'keepBook': {
            const updatedCart = [...state.cart];
            const updatedBook = {...state.book, 'userChoice': 'kept'}
            updatedCart.push({ ...updatedBook});
            return {
                ...state,
                book: null,
                cart: updatedCart,
                lastAction: 'KEEP',
            }
        }
        case 'rejectedBook': {
            return {
                ...state,
                book: null,
                lastAction: 'KEEP(rejected)',
            }
        }
        case 'deleteBook': {
            const oldCart = [...state.cart];
            const newCart = oldCart.filter(cartItem => cartItem.id !== action.payload);
            return {
                ...state,
                cart: newCart,
            }
        }
        case 'preRefurb': {
            const updatedPreRefurb = action.payload;
            return {
                ...state,
                preRefurb: updatedPreRefurb
            }
        }
        
        default: {
            return state;
        }
    }
}

function BookProvider({children}) {
    const [bookState, bookDispatch] = React.useReducer(bookReducer, localState || initialState)

    React.useEffect(() => {
        sessionStorage.setItem('bookState', JSON.stringify(bookState))
    }, [bookState])

    return (
      <BookContext.Provider value={{bookState, bookDispatch}}>
          {children}
      </BookContext.Provider>
    )
}

export { BookProvider, BookContext }