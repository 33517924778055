import React from 'react';
import { useHistory } from "react-router-dom";
import { BookContext } from '../store/BookContext'
import { fetchScan } from '../utils/fetchApi'

import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

// import axios from "axios";
import { Link } from "react-router-dom";
// import { getPricingUrl } from '../utils/pricingUrl'

export default function Scan() {
  const history = useHistory()
  const { bookState, bookDispatch } = React.useContext(BookContext);

  const [ search, setSearch ] = React.useState('');
  const [ query, setQuery ] = React.useState('');
  const [ error, setError ] = React.useState('');
  const [challengeSuccess, setChallengeSuccess] = React.useState(true);

  const recaptchaRef = React.useRef(null)
 
  React.useEffect(()=>{
    var bookNumber = bookState.cart.length
    var launchIsHumanControl = bookNumber % process.env.REACT_APP_CAPTCHA_THRESHOLD_BOOK;
    if (launchIsHumanControl === 0){
        setChallengeSuccess(false)
    }
  },[bookState])

  React.useEffect(() => {
    
    const fetchBook = (retryNumber) =>{
      // axios({
      //   method: "GET",
      //   url: getPricingUrl(query),
      //   // headers: { Authorization: "Bearer " + authState.token },
      //   // data: { id: invoiceState.selectedInvoice.id }
      // })
      fetchScan(query)
      .then((res) => {
        // console.log("res.data", res.data)
        if (res.data.status === 'error') {

          setSearch('')
          if(res.data.code && res.data.message){
            setError(`code erreur ${res.data.code}: ${res.data.message}`)
          }else {
            setError(`Un problème est survenu, réessayez dans un instant. Si le problème persiste, merci de nous contacter.`)
          }
        }else{
          setSearch('')
          if (!res.data.isbn13) {
            bookDispatch({type:'currentBook', payload:{...res.data, isbn13: query}})
          }else {
            bookDispatch({type:'currentBook', payload:res.data})
          }
          history.push('/book')
        }
      })
      .catch((err) => {
        console.log("err",err)
        setSearch('')
       if(retryNumber !==0) {
          setTimeout(()=>{
            fetchBook(retryNumber - 1)
          },3000)
        }else{
          setError(`Un problème est survenu, réessayez dans un instant. Si le problème persiste, merci de nous contacter. (Erreur ${err.errorStatus})`)
        }
      });
    };

    if (query !== '' && challengeSuccess) {
        fetchBook(2)
    }

  }, [query, bookDispatch, history, challengeSuccess])

  React.useEffect(() => {
    const CheckISBN = async () => {
        if (search.length === 13 ) {
            setQuery(search)
        }
      };
    CheckISBN();
  });

    const captchaSubmit = async () => {
        const captchaToken = await recaptchaRef.current.getValue();
        recaptchaRef.current.reset();
        // Pass this token to your server for validation...
        await axios.post(
            `${process.env.REACT_APP_API_URL}/apiFront/captcha`,
          { 
            captchaToken
          }
        ).then(res=>{
            setChallengeSuccess(res.data.success)
        })
      }


  return (
    <div className="h-full px-6 flex flex-col justify-evenly">
      <div className="sm:text-2xl text-xl font-bold border-b-2 border-black">
        Saisissez le code-barres du livre que vous souhaitez vendre :
      </div>
      <form 
        id="scanForm"
        onSubmit={e => {  
          e.preventDefault()
          if(search.length !== 13){
            setError("Nous n'acceptons que les codes ISBN à 13 chiffres")
          }else{
            setQuery(search)
          }
      }}>
        
        <input 
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" 
          type="number" 
          autoFocus
          value={search}
          placeholder="9788437601113"
          onChange={e =>{setError(null); setSearch(e.target.value)}}
        />
             
      </form>
      {!challengeSuccess?
        <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            // size="invisible"
            onChange={captchaSubmit}
        />
        :
        null
      }
      <span className="h-[24px] self-center text-cartHover">
        {error !== '' ?error: null}
      </span>

      <button 
          type="submit"
          form='scanForm'
          className="w-60 text-center self-center px-12 py-3 rounded-md font-bold border bg-white"
        >Valider</button>
      <Link 
          to="/cgu"
          className="w-60 text-center self-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover"
        >Retour</Link>


    </div>
  );
}
  