import { Link } from "react-router-dom";

export default function Cgu() {
    return (
      <div className="w-screen h-screen flex flex-col items-center">
        <div className="h-1/6 mx-4 py-4 flex justify-center">
          <h2 className="sm:text-2xl text-xl text-gray-900 font-extrabold">Conditions Générales d'utilisation</h2>
        </div>
        
        <div className="h-4/6 md:w-4/5 mx-4 p-4 border border-solid border-black overflow-scroll">
          <div className="text-justify">
            <h4 className="text-xl text-gray-900 font-extrabold">Il vous sera nécessaire de saisir une pièce d’identité pour finaliser la vente </h4>
            <br></br>
            <h1 className="text-xl text-gray-900 font-extrabold">Conditions Générales d’Utilisation et d’Achat </h1>
            <br></br>
            <div>
              Société AMMAREAL est une société par actions simplifiée au capital de 25 000 €, immatriculée au registre du commerce et des sociétés de Evry sous le n° 797 906 909 dont le siège social est situé 31, Rue Marcelle Henry, 91200 Athis-Mons.
            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 1 – Application et opposabilité</h2>
            <br></br>
            <div>
              1.1. Les présentes conditions générales d’utilisation et d’achat (ci-après les « CGUA ») de livres par AMMAREAL SAS (ci-après « AMMAREAL ») à des particuliers non professionnels situés en France Métropolitaine (ci-après « le Vendeur ») via
              l’application CIRCULAREAL (ci-après « le Site ») dans leur intégralité et constituent les conditions essentielles et déterminantes de toute offre de reprise de livres d’occasion (ci-après désignés individuellement l’ « Article » ou collectivement
              les « Articles ») transmise par AMMAREAL au Vendeur et validée par celui-ci (ci-après « l’Offre d’achat »).

            </div>
            <br></br>
            <div>
              1.2. Le fait d’accepter une Offre d’achat implique l’acceptation et l’application sans réserve des CGUA. AMMAREAL se réserve le droit de modifier les CGUA à tout moment, auquel cas la nouvelle version ne s’appliquera pas aux Offres d’achat en
              cours. Le fait, pour AMMAREAL, de ne pas se prévaloir, à un moment donné, d’une quelconque clause des CGUA, ne saurait être interprété comme une renonciation à se prévaloir ultérieurement de l’ensemble des clauses. Si l’une des clauses des CGUA se
              trouve nulle ou annulée pour quelque raison que ce soit, les autres clauses n’en seront pas affectées.

            </div>
            <br></br>
            <div>
              1.3 La vente en ligne par des particuliers est encadrée par les dispositions générales du Code de commerce, du Code général des impôts et du Code du travail.
            </div>
            <br></br>
            <div>
              Dans le cadre de son service d’achat d’articles d’occasion, AMMAREAL pourra refuser toute vente dès lors que l'utilisation de ce service présentera un caractère assimilable à une activité commerciale.

            </div>
            <div>
              Le caractère commercial d'une activité est défini par son caractère habituel et son but lucratif.

            </div>
            <div>
              Ces Conditions s'appliquent à l'exclusion de toutes autres conditions, notamment celles en vigueur pour les ventes en magasin, ou celles applicables aux transactions effectuées dans le cadre du service de vente en ligne, ou aux transactions
              effectuées via l’espace professionnel réservé et dédié aux clients ayant bénéficié de l’ouverture d’un compte professionnel.

            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 2- Capacité – Acceptation des CGUA</h2>
            <br></br>
            <h3>2.1 Capacité – Utilisation du Site</h3>
            <br></br>
            <div>
              Le Service d’achat d’Articles d’occasion (CIRCULAREAL) a pour fonction de vous assister dans le processus de reprise de vos Articles. Afin de pouvoir utiliser CIRCULAREAL, vous devez impérativement être capable juridiquement de contracter
              (c’est-à-dire pour tout ressortissant français, être majeur ou émancipé) et résider en France métropolitaine au jour de l’Offre d’achat de vos Articles. Afin de justifier de votre âge, vous devez remplir les champs requis figurant dans la page «
              Création de comptes » et les maintenir à jour dans l’onglet « Mes données personnelles vendeur » sur votre compte personnel, et notamment :
              <div >•<span > </span>Le type de document vous permettant d’attester votre identité : carte d’identité, passeport, permis de conduire, carte de séjour ;</div>
              <div >•<span > </span>Le numéro du document choisi ;</div>
              <div >•<span > </span>Le nom de l’autorité ayant délivré le document ;</div>
              <div >•<span > </span>La date de délivrance du document ;</div>
              <div >•<span > </span>Votre numéro de téléphone ;</div>
              <div >•<span > </span>Votre date de naissance.</div>
            </div>
            <br></br>
            <div>
              En cliquant sur la case « J’atteste sur l’honneur la véracité de ces informations » puis sur l’icône « Valider », vous certifiez être majeur au jour de l’Offre d’achat de vos Articles et vous certifiez la véracité des informations fournies sur
              votre compte.

            </div>
            <br></br>
            <div>
              Sauf cas de fraude dont il vous appartient de rapporter la preuve, vous êtes responsable financièrement de vos démarches sur le Site, notamment de l’utilisation qui sera faite de votre nom d’utilisateur et de votre mot de passe.

            </div>
            <br></br>
            <div>
              Une utilisation du Site qui serait frauduleuse, ou qui contreviendrait aux présentes CGUA de reprise de livre d’occasion, justifiera que vous soit refusé, à tout moment, l’accès à CIRCULAREAL ou aux autres fonctionnalités du Site.

            </div>
            <div>
              Conformément aux articles R.321-1 et suivants du Code pénal, vous autorisez AMMAREAL à inscrire sur son registre déclaré en préfecture, outre la description des Articles vendus, vos noms, prénoms, qualité et domicile ainsi que la nature, le numéro
              et la date de délivrance de la pièce d'identité produite avec l'indication de l'autorité qui l'a établie.

            </div>
            <br></br>
            <h3>2.2 Acceptation des Conditions Générales d’Achat
            </h3>
            <br></br>
            <div>
              Aucune Offre d’achat ne peut être formulée par AMMAREAL sans acceptation des CGUA du Site. Vous pouvez prendre connaissance des CGUA à tout moment lors de votre navigation sur le Site.

            </div>
            <div>
              Votre acceptation effective des présentes CGUA se fera lors de la confirmation de votre Offre d’achat. Il vous sera demandé de reconnaître avoir pris connaissance de l’intégralité des présentes CGUA et de les accepter de manière irrévocable,
              entière et sans réserve en cliquant sur la case « J’accepte les CGUA d’AMMAREAL ». La confirmation de votre vente ne pourra se faire qu’après acceptation des présentes CGUA.

            </div>
            <div>
              Sauf preuve contraire, les données enregistrées par le Site lors de la transaction, conformément aux dispositions de l’article 7 des présentes CGUA, constitueront la preuve de l’ensemble des opérations passées par AMMAREAL, et notamment de
              l’acceptation des CGUA.

            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 3 – Offre d’achat
            </h2>
            <br></br>
            <div>
              AMMAREAL s’engage à formuler une Offre d’achat de vos Articles dans le respect des conditions ci-dessous énoncées.

            </div>
            <br></br>
            <h3>3.1 Accès au Site
            </h3>
            <br></br>
            <div>
              Le seul accès au Site est totalement gratuit et se fait uniquement par l’intermédiaire de notre partenaire la société {process.env.REACT_APP_NAME.toUpperCase()}, via son site internet furet.com et/ou ses points de vente.

            </div>
            <br></br>
            <h3>3.2 Compte personnel
            </h3>
            <br></br>
            <div>
              Afin de pouvoir vendre vos Articles sur le Site, vous devez vous connecter sur votre compte personnel CIRCULAREAL ou, si c’est votre première vente, créer un compte personnel CIRCULAREAL en fournissant diverses informations nécessaires au
              traitement de votre vente, et notamment votre adresse électronique et un mot de passe, et aux obligations légales.

            </div>
            <div>
              L’ensemble des informations ainsi collectées est traité par AMMAREAL via CIRCULAREAL conformément aux dispositions de l’article 7 des présentes CGUA.

            </div>
            <br></br>
            <h3>3.3 Conditions relatives à vos Articles
            </h3>
            <br></br>
            <div>
              Les Articles proposés à l’achat doivent scrupuleusement correspondre à notre Charte de qualité mise à disposition et consultable en ligne et/ou en magasin.

            </div>
            <div>
              Les Offres d’achat ne sont valables que pour les livres respectant les critères de qualité établis par AMMAREAL qui n’acceptera que les livres disposant d’un numéro ISBN présenté sous la forme d’un code barre et en bon état général. Les livres
              doivent en conséquence ne présenter aucun ajout, enlèvement ou modification extérieurs ou défaut apparent. À titre illustratif et non exclusif, ils doivent posséder toutes leurs pages, la couverture ne doit pas être endommagée et les coins ne
              doivent pas être frottés. La couverture ne doit pas être gondolée et les pages ne doivent pas être collées ou jaunies. Le livre ne doit pas comporter de mentions manuscrites, des mots raturés, entourés, soulignés, ou des passages surlignés.

            </div>
            <br></br>
            <div>
              Nous vous rappelons en particulier que :
              <div >•<span > </span>Les articles proposés à l’achat doivent être en bon état ;</div>
              <div >•<span > </span>Les articles promotionnels impropres à la vente ne peuvent être repris ;</div>
              <div >•<span > </span>Les articles dépourvus de code-à-barre (notamment ISBN ou EAN) ne sont pas repris ;</div>
              <div >•<span > </span>Vous ne pouvez pas proposer plusieurs exemplaires d’un même article que ce soit dans une même vente.</div>

            </div>
            <br></br>
            <h3>3.4 Estimation d’une cotation</h3>
            <br></br>
            <div>
              Afin d’estimer le montant total de notre Offre, le Vendeur peut scanner le code-barres situé sur le livre qu’il souhaite proposer à la vente ou rentrer manuellement le code-barres. AMMAREAL affiche alors sur le Site une offre de reprise ou indique
              au Vendeur via le Site que le livre ne peut pas faire l’objet d’une offre de reprise.

            </div>
            <br></br>
            <div>
              Dans le cas où une offre de reprise a été réalisée par AMMAREAL, le livre sera ajouté à son panier de vente. Le panier de vente peut être modifié à tout instant en ajoutant des livres ayant fait l’objet d’une offre de reprise ou supprimer des
              livres.

            </div>
            <br></br>
            <div>
              Le panier préétabli sur internet est valable pour une durée de 14 jours pour présentation et reprise des Articles en magasin {process.env.REACT_APP_NAME.toUpperCase()}.

            </div>
            <br></br>
            <div>
              Le panier sera validé une fois le Vendeur identifié ou une fois le compte créé.

            </div>
            <br></br>
            <div>
              Aucun achat de livre ne peut être réalisé par AMMAREAL si l’une des informations suivantes est manquante : nom, prénom, email, adresse postale. Le Vendeur est responsable des informations transmises et s’engage à ne transmettre que des informations
              exactes. AMMAREAL ne pourra être tenue responsable dans le cas où le Vendeur aurait indiqué des informations inexactes ou erronées.

            </div>
            <br></br>
            <div>
              Seuls les Articles dont le code-barres correspond à un produit référencé dans notre base de données pourront être inclus dans une Offre. Une fois vos Articles enregistrés, il vous faut cliquer sur « PANIER » afin que AMMAREAL vous indique le
              montant total de son Offre.

            </div>
            <br></br>
            <h3>3.5 Confirmation de l’Offre
            </h3>
            <br></br>
            <div>
              Pour accepter l’Offre d’AMMAREAL au montant de la cotation indiquée, vous devez cliquer sur « TERMINER». Un récapitulatif de l’Offre d’achat vous est alors présenté.

            </div>
            <br></br>
            <h3>3.6.1 - Réalisation définitive du contrat
            </h3>
            <br></br>
            <div>
              La réalisation de la vente est conditionnée par l’inspection de vos Articles par un conseiller de notre partenaire {process.env.REACT_APP_NAME.toUpperCase()}.

            </div>
            <div>
              Si les Conditions sont réunies, notre partenaire {process.env.REACT_APP_NAME.toUpperCase()} éditera un bon d’achat utilisable en caisse.

            </div>
            <div>
              La vente sera pleinement, entièrement et irrévocablement formée après la remise de vos Articles à notre partenaire, AMMAREAL deviendra alors pleinement propriétaire de vos Articles.

            </div>
            <br></br>
            <h3>3.6.2 Défectuosité, non-conformité
            </h3>
            <br></br>
            <div>
              Vous serez averti par le conseiller {process.env.REACT_APP_NAME.toUpperCase()} lorsqu’un ou plusieurs de vos Articles sont, après inspection par celui-ci, jugés comme étant défectueux ou non conformes à l’Offre d’achat et à la Charte de reprise CIRCULAREAL.

            </div>
            <br></br>
            <div>
              3.6.2.1 Vous acceptez dans ce cas que seuls le ou les Articles conformes à l’Offre d’achat et respectant la Charte de reprise CIRCULAREAL vous soient réglés pour un prix global corrigé qui sera recalculé par le Site. Ce nouveau prix global, calculé
              pour l’ensemble des Articles conformes, sera égal à la somme des valorisations unitaires déterminées au moment de l’acceptation par le Vendeur de l’Offre d’achat d’origine pour les Articles concernés.

            </div>
            <br></br>
            <div>
              La vente sera pleinement, entièrement et définitivement formée après la remise de vos Articles à notre partenaire {process.env.REACT_APP_NAME.toUpperCase()}, AMMAREAL deviendra alors pleinement propriétaire de vos Articles.

            </div>
            <br></br>
            <div>
              3.6.2.2 -Pour vos Articles jugés comme étant défectueux ou non conformes à l’Offre ou à la Charte CIRCULAREAL, vous aurez la possibilité soit de les reprendre, soit de les donner à notre partenaire en les déposant dans le bac ou l’armoire prévus à
              cet effet en magasin.

            </div>
            <br></br>

            <h2 className="text-gray-900 font-extrabold">Article 4 – Prix</h2>
            <br></br>
            <div>
              Le prix versé par AMMAREAL est le prix total proposé dans l’Offre d’achat CIRCULAREAL en fonction de la cotation réalisée initialement ou le prix recalculé en cas d’Articles jugés comme étant défectueux ou non conformes à l’Offre d’achat ou à la
              Charte de qualité CIRCULAREAL. Tous les achats, quelle que soit leur provenance, seront payés en valeur de crédit par l’édition d’un bon d’achat papier valable en caisse et en valeur EUROS par l’intermédiaire de notre partenaire {process.env.REACT_APP_NAME.toUpperCase()}.

            </div>
            <br></br>
            <div>
              AMMAREAL précise que les prix proposés pour le rachat d’Articles sont valables uniquement au moment de la cotation. Les prix d’achat des Articles peuvent évoluer dans le temps.

            </div>
            <div>
              En utilisant le Site CIRCULAREAL, vous reconnaissez agir comme dans un but privé, et ne pas être un vendeur professionnel. A ce titre, le rachat des Articles par AMMAREAL ne donnera pas lieu au paiement de la TVA.

            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 5 – Paiement</h2>
            <br></br>
            <div>
              Le paiement sera généré par un bon dématérialisé que le partenaire {process.env.REACT_APP_NAME.toUpperCase()} s’engage à éditer en version papier pour utilisation en caisse.

            </div>
            <div>
              Le bon d’achat sera valable 2 mois dans le réseau de notre partenaire et devra être utilisable pour un minimum d’achat de 5 euros.

            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 6 – Responsabilité
            </h2>
            <br></br>
            <div>
              Les services proposés sont conformes à la législation française en vigueur.

            </div>
            <br></br>
            <div>
              AMMAREAL et son partenaire ne sauraient être tenus pour responsable de l’inexécution du contrat conclu au cas où l'inexécution de ses obligations serait imputable soit au fait imprévisible et insurmontable d'un tiers au contrat, soit à un cas de
              force majeure ou d'un cas fortuit, de votre fait ou du fait d’un tiers ou de causes extérieures telles que les conflits sociaux, intervention des autorités civiles ou militaires, catastrophes naturelles, incendies, dégâts des eaux.

            </div>
            <br></br>
            <div>
              De même, la responsabilité d’AMMAREAL et de son partenaire ne saurait être engagée pour tous les inconvénients ou dommages inhérents à l'utilisation du réseau Internet, notamment une rupture de service, interruption du réseau de télécommunications
              ou du réseau électrique dont elle ne serait pas à l’origine.

            </div>
            <br></br>
            <div>
              Tout Article identifié comme volé ou perdu sera remis aux autorités compétentes sans que la responsabilité d’AMMAREAL et de son partenaire puisse être recherchée.

            </div>
            <br></br>
            <div>
              La responsabilité d’AMMAREAL et de son partenaire ne pourra également pas être engagée en cas d’usage frauduleux de votre identifiant ou de votre mot de passe.

            </div>
            <br></br>


            <h3>Article 7 – Traitement des données à caractère personnel</h3>
            <br></br>
            <div>
              AMMAREAL en tant que responsable de traitement, collecte vos données personnelles et cela de manière obligatoire afin de procéder au rachat de vos livres d’occasion et de tenir le registre de Police.

            </div>
            <br></br>
            <div>
              La tenue de ce registre est une obligation légale pour AMMAREAL, imposée par les articles 321-7 et 321-8 du Code pénal. La base légale de ce traitement est donc le respect d’une obligation légale. Les destinataires des données sont AMMAREAL et les
              autorités de contrôle compétentes.

            </div>
            <br></br>
            <div>
              AMMAREAL conserve vos données personnelles pendant une durée de 10 ans à partir de leur collecte.

            </div>
            <br></br>
            <div>
              Conformément à la réglementation européenne sur la protection des données, vous disposez d’un droit d’accès, de rectification, d’effacement et de limitation sur vos données. Vous pouvez adresser votre demande par courrier électronique à l’adresse
              dpo@ammareal.fr ou par courrier postal Ammareal SAS, 31, rue Marcelle Henri – 91200 Athis-Mons.

            </div>
            <br></br>

            <div>
              Vous disposez également du droit d’introduire une réclamation auprès de la CNIL.

            </div>
            <div>
              Pour plus d’informations sur la signification des droits et sur l’introduction d’une réclamation, veuillez trouver le site de la CNIL : https://www.cnil.fr/. »

            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 8 – Propriété intellectuelle
            </h2>
            <br></br>
            <div>
              Le Site, son contenu et tous les éléments le constituant, sont des créations pour lesquelles AMMAREAL et/ou, le cas échéant, des Partenaires, sont titulaires de l'intégralité des droits de propriété intellectuelle et/ou des droits d'exploitation,
              en particulier au titre du droit d'auteur, du droit des bases de données, du droit des marques, et du droit des dessins et modèles.

            </div>
            <br></br>
            <div>
              Le Site, ainsi que les logiciels, bases de données, textes, informations, analyses, images, photographies, graphismes, logos, sons ou toutes autres données contenues sur le Site demeurent la propriété exclusive d’AMMAREAL et/ou des Partenaires ou,
              le cas échéant, de leurs propriétaires respectifs avec qui ces derniers ont passé des accords d'utilisation.

            </div>
            <br></br>
            <div>
              Il vous est concédé un droit d'utilisation non-exclusif, non transférable et dans un cadre privé, du Site et des données contenues sur le Site. Le droit ainsi concédé consiste en un droit de consulter en ligne les données et informations contenues
              sur le Site. Ce droit d'utilisation s'entend uniquement d’un usage strictement privé.

            </div>
            <br></br>
            <div>
              Toute autre utilisation du Site, notamment commerciale, de votre part est interdite, et est constitutive de contrefaçon, sanctionnée en tant que telle par le Code de la Propriété intellectuelle. Vous vous interdisez notamment, de manière non
              exhaustive, de reproduire et/ou représenter pour un usage autre que privé, vendre, distribuer, émettre, traduire, adapter, diffuser et communiquer intégralement ou partiellement sous quelque forme que ce soit tout élément, information ou donnée du
              Site.

            </div>
            <br></br>
            <div>
              En outre, vous vous interdisez d'introduire, par quelque moyen que ce soit, des données susceptibles de modifier ou porter atteinte au contenu ou la présentation du Site.

            </div>
            <br></br>
            <div>
              Tout lien hypertexte avec le Site, quel que soit le type de lien, doit faire l'objet d'une autorisation préalable de AMMAREAL, agissant au nom et pour le compte du titulaire du droit concerné, sur support papier ou électronique.

            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 9 – Litige - Droit Applicable
            </h2>
            <br></br>
            <div>
              {/* Les présentes CGUA sont soumises à la loi française. */}
              Conformément aux dispositions du Code de la consommation concernant le règlement amiable des litiges, SAS Ammareal adhère au Service du Médiateur du e-commerce de la FEVAD (Fédération du e-commerce et de la vente à distance) dont les coordonnées sont les suivantes : 
              <br></br>
              Médiateur de la consommation FEVAD
              BP 20015 - 75362 PARIS CEDEX 8 - <a className="text-blue-700 underline" href='https://www.mediateurfevad.fr' target='_blank' rel="noreferrer">https://www.mediateurfevad.fr</a>. Après démarche préalable écrite des consommateurs vis-à-vis de SAS Ammareal, le Service du Médiateur peut être saisi pour tout litige de consommation dont le règlement n’aurait pas abouti. Pour connaître les modalités de saisine du Médiateur, cliquer  
               {" "}<a className="text-blue-700 underline" href="https://www.mediateurfevad.fr/index.php/espace-consommateur-2/#msdynttrid=aBApxVZOiuCNmdkmh89TpzWQcRAv8I1S8uR3TBkVHTA" target='_blank' rel="noreferrer">ici</a>.
            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 10 - Service relation client – Contacts divers – Coordonnées.</h2>
            <br></br>
            <div>
              Conformément à l’article L.121-18 du Code de la Consommation, vous trouverez ci-dessous l’ensemble des informations vous permettant de contacter effectivement les services d’AMMAREAL.

            </div>
            <br></br>
            <div>
              Pour toute information ou question ou suggestion, vous pouvez contacter le service relation client :
            </div>
            <br></br>
            <div>
              Par courriel : client@ammareal.fr
            </div>
            <div>
              Par téléphone : 01 69 39 49 56
            </div>
            <br></br>
            <div>
              Hébergeur du site CIRCULAREAL
            </div>
            <div>
              OVH Siège social : 2 rue Kellermann – 59100 Roubaix – France
            </div>
            <br></br>
            <h2 className="text-gray-900 font-extrabold">Article 11 - Dispositions finales</h2>
            <br></br>
            <div>
              Le fait que AMMAREAL ne se prévale pas, à un moment donné, d'une des dispositions des Conditions Générales d’Achat et d’Utilisation ne pourra être interprété comme valant renonciation par elle-même à se prévaloir ultérieurement de l'une de ces
              dispositions.

            </div>
            <br></br>
            <div>
              Dans le cas où l'une des dispositions des CGUA serait déclarée nulle ou sans effet, cette disposition serait réputée non écrite, sans que cela n'affecte la validité des autres dispositions, sauf si la disposition déclarée nulle ou sans effet était
              essentielle et déterminante.

            </div>

          </div>  
        </div>
        <div className="w-screen h-2/6 flex flex-col-reverse md:flex-row items-center justify-around">
          <Link 
            to="/"
            className="w-60 my-2 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover"
          >Retour</Link>
          <Link
            to="/scan"
            className="w-60 my-2 text-center px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white"
          >Accepter</Link>
        </div>
      </div>
    );
  }
  
  
  