import React from 'react';

import { Link } from "react-router-dom";

export default function AuthChoice() {

    return (
      <div className="h-full px-6 flex flex-col justify-evenly items-center">
          <h3 className="text-2xl font-bold border-b-2 border-black">Pour finaliser la reprise, je me connecte</h3>
       
          <Link to="/auth/login" className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white">S'identifier</Link>
          <Link to="/auth/signup" className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white">Créer un compte</Link>
          <Link to="/scan" className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover">Retour</Link>
        
      </div>
    );
}