import React, { useState } from 'react';

import { Switch, Route, useHistory } from "react-router-dom";

import Reset from './components/Reset';
import HomePage from "./page/HomePage";
import CguPage from "./page/CguPage";
import ScanCartPage from "./page/ScanCartPage";
import BookPage from './page/BookPage';
import CheckoutPage from './page/CheckoutPage';
import AuthPage from './page/AuthPage';
import InfosPage from './page/InfosPage';
import FlashMessage from './components/flashMessage'; 
import { useIsNetworkAvailable } from "./utils/networkError";

import packageJson from "../package.json";
import moment from "moment";

export default function App() {
  const history = useHistory();
  const [displayReset, setDisplayReset] = useState(false);
  const isConnected = useIsNetworkAvailable();
  // console.log("isConnected",isConnected)

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };
  const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);
  
    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
      return true;
    } else {
      return false;
    }
  };
  React.useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        // console.log("meta",meta)

        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;
        console.log("latestVersionDate",latestVersionDate)
        console.log("currentVersionDate",currentVersionDate)

        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          console.log("refresh forced")
          // setIsLatestBuildDate(false);
          refreshCacheAndReload();
        } 
      });
  }, []);

  React.useEffect(() => {
    return history.listen(location => {
      if (location.pathname !== "/") {
        setDisplayReset(true)
      } else {
        setDisplayReset(false)
      }
      if (history.action === 'POP') {
        history.go(1);
      }
    })
  }, [history]);

  return (
    <>
      {
        !isConnected ?<FlashMessage/>: null
      }
   
      {
        displayReset ? <Reset /> : null
      }
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/cgu" component={CguPage} />
        <Route exact path="/scan" component={ScanCartPage} />
        <Route exact path="/book" component={BookPage} />
        <Route exact path="/checkout" component={CheckoutPage} />
        <Route exact path="/auth/:type" component={AuthPage} />
        <Route exact path="/infos/:type" component={InfosPage} />
      </Switch>
    </>
  );
}


