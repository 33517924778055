import React from 'react';

export default function Flash (){
    // const [visibility, setVisibility] = React.useState(false)

    // React.useEffect(()=>{
    //     if(!connectivityState.connectivity){
    //         setVisibility(true)
    //         setTimeout(()=>{
    //             setVisibility(false)
    //         },7000)
    //     }
    // },[connectivityState])
    
    return (
        // visibility && 
        <div className="flex align-items bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded absolute top-0 w-full" role="alert" >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <strong className="font-bold mr-1 ml-1">
                Problème réseau, 
            </strong> 
            vérifiez l'état de votre connexion Internet.
        </div>
    )
}