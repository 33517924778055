import React from 'react';
import { AuthContext } from '../store/AuthContext'

import AuthChoice from "../components/AuthChoice";
import Cart from "../components/Cart";
import PreRefurb from '../components/PreRefurb';

export default function CheckoutPage() {
    const { authState } = React.useContext(AuthContext);

    return (
        <div className="w-screen h-screen flex flex-col md:flex-row items-center justify-around">
            <div className="w-full md:w-7/12 h-1/2 md:h-3/4">
                { authState.customer && authState.customer._id?
                    <PreRefurb/>
                : 
                    <AuthChoice /> 
                }       
            </div>

            <div className="w-full md:w-5/12 h-3/6 md:h-3/4">
                <Cart checkout={true} />
            </div>    
                 
      </div>
    );
  }
  
  
  