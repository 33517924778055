import React from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../store/AuthContext'
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';


import "react-datepicker/dist/react-datepicker.css";
// import { RiCalendar2Line } from 'react-icons/ri';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';

import { Link } from "react-router-dom";
import axios from "axios";
var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

registerLocale('fr', fr)

export default function Signup() {
  const history = useHistory()
  const [passwordShown, setPasswordShown] = React.useState(false);

  const { authState, authDispatch } = React.useContext(AuthContext);
  const [ step, setStep ] = React.useState(1);
  const [ error, setError ] = React.useState('');
  const [ query, setQuery ] = React.useState('');
  const [ customer, setCustomer ] = React.useState({
    appellation: '',
    firstName: '',
    lastName: '',
    address: '',
    postcode: '',
    city: '',
    country: '',
    personalIdType: '',
    personalIdNumber: '',
    personalIdDeliveryDate: '',
    personalIdDeliveryFrom: '',
    email: '',
    username: '',
    password: '',
    optin:false,
    optin_newsletter: false,
  
  });

  React.useEffect(() => {
    const signupCustomer = () =>{

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/apiIframe/customer/signup`,
        headers: { Authorization: "Bearer " + authState.token },
        data: query
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.status) {
          authDispatch({type:'customerAuth', payload: res.data.customer});
          history.push('/checkout')
        }else{
          if(res.data.error){
          console.log("error",res.data.error);

            setError(res.data.error) 
          }
        }
      })
      .catch((err) => {
        setQuery('')
        console.log("error",err);
        setError(err) 

      });

    }

    if (query !== '') {
      setError('')
      setQuery('')
      signupCustomer();
    }
}, [query, authDispatch, authState, history])

const checkOptins = (origin, bool)=>{
  if(origin==="optin"){
    setCustomer({...customer, optin : bool})
  }else{
    setCustomer({...customer, optin_newsletter : bool})
  }
}

  return (

  <div className='flex-col flex justify-center items-center w-4/5'>
    <div className='flex justify-start w-full mt-5'>
      <img alt='logo ammareal' src='../../logos/ammareal-text-prussian.svg'></img>
    </div>

      <h3 className="text-2xl pt-5">Création de compte {step}/2</h3>

       {step === 1 ?
      <form 
        className='h-5/6 w-full flex flex-col justify-evenly'
        onSubmit={e => {
            setError('')
            e.preventDefault();
            setStep(2)
        }}>
        
        <div className='md:h-4/6 py-4 flex flex-col justify-center'>


          <div className="flex flex-col md:flex-row justify-evenly">
            <div className='md:w-1/2 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Adresse mail
              </label>
              <input
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="votre.adresse@email.fr"
                type ="email"
                onChange={e => setCustomer({...customer, email: e.target.value.toLowerCase()}) }
                value={customer.email || ''}
                required
              />
            </div>

            <div className='md:w-1/2 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Mot de passe
              </label>

              <div className="relative mt-1">
                  
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    placeholder="Mot de passe"
                    onChange={e => setCustomer({...customer, password: e.target.value}) }
                    value={customer.password || ''}
                    required
                  />   
                  <div 
                    className="flex absolute inset-y-0 right-2 items-center pl-3 cursor-pointer"
                    onClick={()=> setPasswordShown(!passwordShown)}
                    >
                      {!passwordShown ? 
                        <FaEye className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                      :
                        <FaEyeSlash className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                      }
                  </div>                
                </div>
                <small className="text-xs text-gray-500">Minimum 8 caractères, 1 majuscule et 1 chiffre</small>


              {/* <input
                type="password"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="V0tr3 m0t d3 p@ss3"
                pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}"
                onChange={e => setCustomer({...customer, password: e.target.value}) }
                value={customer.password || ''}
                required
              /> */}
              
            </div> 
          </div>

          <div className="flex flex-col md:flex-row justify-evenly ">
            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Civilité
              </label>
              <select className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={ customer.appellation || ''}
                  onChange={e => setCustomer({...customer, appellation: e.target.value}) }
                  required
              >
                  <option value='' disabled>-</option>
                  <option value='M'>M</option>
                  <option value='Mme'>Mme</option>
              </select>
            
            </div>

            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Prénom
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Votre prénom"
                onChange={e => setCustomer({...customer, firstName: e.target.value}) }
                value={customer.firstName || ''}
                required
              />
            </div> 
            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Nom
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Votre nom"
                onChange={e => setCustomer({...customer, lastName: e.target.value}) }
                value={customer.lastName || ''}
                required
              />
            </div> 
          </div>

          <div className="flex flex-col md:flex-row justify-evenly">
            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Adresse
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="N° et voie"
                onChange={e => setCustomer({...customer, address: e.target.value}) }
                value={customer.address || ''}
                required
              />
            </div>

            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Code Postal
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="123456"
                onChange={e => setCustomer({...customer, postcode: e.target.value}) }
                value={customer.postcode || ''}
                required
              />
            </div> 
            <div className='md:w-1/3 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Ville
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Votre ville"
                onChange={e => setCustomer({...customer, city: e.target.value}) }
                value={customer.city || ''}
                required
              />
            </div> 
          </div>
          <div className="flex flex-col md:flex-row">
            <div className='md:w-1/3 px-2 mt-4'>
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                Pays
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Pays"
                onChange={e => setCustomer({...customer, country: e.target.value}) }
                value={customer.country || ''}
                required
              />
            </div>

        
          </div>
        </div>

        <span className="self-center text-cartHover">
          {error ? error.message : null}
        </span>

        <div className='md:h-2/6 flex flex-col items-center justify-evenly'>
          <button type="submit" className="w-60 my-2 text-center px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white">Valider</button>
          <Link to="/checkout" className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover">Retour</Link>
        </div>

      </form>
       :
      <form 
        className='h-5/6 w-full flex flex-col justify-evenly'
        onSubmit={e => {
            e.preventDefault()
            setQuery(customer)
        }}>
  
        <div className='md:h-4/6 py-4 flex flex-col justify-center'>

          <div className="flex flex-col justify-evenly">
            <div className='md:w-1/2 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
                Type de document d'identité
              </label>
              <select className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={ customer.personalIdType || ''}
                  onChange={e => setCustomer({...customer, personalIdType: e.target.value}) }
                  required
              >
                  <option value='' disabled>-- Sélectionner un type --</option>
                  <option value='Passport'>Passeport</option>
                  <option value="idCard">Carte d'identité</option>
              </select>
            
            </div>

            <div className='md:w-1/2 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
              Numéro de la pièce d'identité
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder=""
                
                onChange={e => setCustomer({...customer, personalIdNumber: e.target.value}) }
                value={customer.personalIdNumber || ''}
                required
              />
            </div> 
          </div>

          <div className="flex flex-col md:flex-row justify-evenly">

            <div className='md:w-1/2 px-2 mt-4'>
                <label className="block text-sm font-medium text-gray-700">
                Délivrée le
                </label>
                <div className='flex items-center'>

                  <DatePicker
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      locale='fr'
                      dateFormat="dd/MM/yyyy"
                      onChange={date => setCustomer({...customer, personalIdDeliveryDate: date})}
                      selected={customer.personalIdDeliveryDate || ''}
                      required  
                  />
                  {/* <RiCalendar2Line 
                    className='mx-2 text-3xl'
                  /> */}
                </div>
            </div>

            <div className='md:w-1/2 px-2 mt-4'>
              <label className="block text-sm font-medium text-gray-700">
              Délivrée par
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder=""
                onChange={e => setCustomer({...customer, personalIdDeliveryFrom: e.target.value}) }
                value={customer.personalIdDeliveryFrom || ''}
                required
              />
            </div> 

          </div>

            <div className='px-2 mt-4'>
              <div className='flex items-center'>
                <input type="checkbox" name='optin'
                    onChange={()=>checkOptins("optin", !customer.optin)}
                  
                />
                <label className="mx-2 block text-sm font-medium text-gray-700">
                  Recevoir les offres de nos partenaires
                </label>
              </div>
              <div className='flex items-center'>
                <input type="checkbox" name='optin_newsletter'
                    onChange={()=>checkOptins("newsletter",!customer.optin_newsletter)}
                />
                <label className="mx-2 block text-sm font-medium text-gray-700">
                  Recevoir notre newsletter. <br></br>
                  <span className='text-xs italic'>Vous pouvez vous désinscrire à tout moment. Vous trouverez pour cela nos informations de contact dans les conditions d'utilisation du <a rel="noopener noreferrer" href="https://www.ammareal.fr/content/14-conditions-generales-de-vente"  target="_blank">site</a>.</span>
                </label>
              </div>
              <div className='flex items-center'>
                <input type="checkbox" 
                  required/>
                <label className="mx-2 block text-sm font-medium text-gray-700">
                  J’atteste sur l’honneur que les informations renseignées ci-dessus sont certifiées exactes 
                </label>

              </div>
          </div>
          <div className='my-2 text-xs font-medium text-gray-700'>
            <div>
            Ammareal, en tant que responsable de traitement, collecte vos données personnelles de manière obligatoire afin de procéder au rachat de vos livres d’occasion et de tenir le registre de Police qui constitue une obligation légale concernant ce type d’opération. La base légale de ce traitement est donc le respect d’une obligation légale. Les destinataires des données sont Ammareal et les autorités de contrôle compétentes. Ammareal conserve vos données personnelles pendant une durée de 10 ans à partir de leur collecte.
            </div>
            <br></br>
            <div>
            Conformément au RGPD, vous disposez d’un droit d’accès, de rectification, d’effacement et de limitation sur vos données. Vous pouvez adresser votre demande par courrier électronique à l’adresse dpo@ammareal.fr ou par courrier postal : Ammareal SAS, 31, rue Marcelle Henri – 91200 Athis-Mons.

            </div>
            <div>
            Vous disposez également du droit d’introduire une réclamation auprès de la CNIL.

            </div>
            <div>
            Pour plus d’informations sur la signification des droits et sur l’introduction d’une réclamation, veuillez trouver le site de la CNIL : https:// www.cnil.fr/.

            </div>
          </div>
          
        </div>
 

     
        
          

        <span className="self-center text-cartHover">
          {error ? error.message : null}
        </span>

        {
          (error && error.alreadyExists) ? 
        <div className='md:h-2/6 flex flex-col items-center justify-evenly'>
          <Link 
            to="/auth/login"
            className="w-60 my-2 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover"
          >
            Je m'identifie
          </Link>
        </div>

          : 
        
        <div className='md:h-2/6 flex flex-col items-center justify-evenly'>
          <button 
            type='submit'
            className="w-60 text-center mb-2 px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white"
          >Valider</button>
          <button 
            className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover"
            onClick={() => setStep(1)}
          >Retour</button>
        </div>
      }
      </form>

       }
    </div>

  );
}