import React from 'react';
import { Link } from "react-router-dom";

import { BookContext } from '../store/BookContext'

import { RiDeleteBin5Line } from 'react-icons/ri';

export default function Cart(props) {
  const { bookState, bookDispatch } = React.useContext(BookContext);
  let filteredCart = bookState.cart.filter(book => book.userChoice === 'sold')
  const totalPrice = filteredCart.reduce((count, curItem) => count + curItem.price, 0)

    return (
        <div className="h-full px-6 flex flex-col items-center  ">
            <div className="h-full w-full mx-3 flex flex-col justify-between bg-greyMain">
              <h3 className="text-2xl pl-6 pt-3">Panier de reprise</h3>
              
              <div className="h-2/4 w-full px-6 pt-3 flex flex-col overflow-x-hidden">
              {filteredCart.map((book, index) => {
                  return (
                    <div 
                      key={index}
                      className='flex justify-between pt-3'>
                      <span className='w-4/6'>{book.title}</span>
                        <span className='w-2/6 flex justify-center'>

                          { bookState.preRefurb === null ?

                            <RiDeleteBin5Line 
                                className='mx-2 text-cartMain hover:text-cartHover text-2xl cursor-pointer'
                                onClick={() => bookDispatch({ type:'deleteBook', payload:book.id })}
                            />

                          : null}

                          <span className='whitespace-nowrap'>{book.price.toFixed(2).replace('.', ',') + " €"}</span>
                          
                        </span>

                        
                      
                    </div>
                  )      
                })}
              </div>
             
                
              
          
            <div className='h-1/4 border-t flex flex-col justify-evenly bg-white pt-5'>
              <div className="px-6 flex justify-between items-center">
                  <span>Nombre de livres</span>
                  <span>{filteredCart.length}</span>
              </div>
              <div className="max-w-xl border-t px-6 flex justify-between">
                  <div className='flex flex-col'>
                    <span className='text-greenMain text-xl'>Total reprise*</span>
                    <span className='text-xs text-gray-500'>*Sous réserve de la validation du libraire conformément à notre Charte Qualité</span>
                  </div>
                  <span className='text-greenMain text-xl flex justify-end w-full'>{totalPrice.toFixed(2).toString().replace('.', ',')} €</span>
              </div>
              
            </div>
            
          </div>
         
          
          
        {!props.checkout ?  

            <div className="h-1/4 p-3 flex justify-center items-center ">
              { filteredCart.length > 0 ?
                <Link to="/checkout" className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white">Terminer le panier</Link>
              : null }
            </div>

        : null}
      </div>
    );
}