import React from 'react';

const AuthContext = React.createContext();
const localState = JSON.parse(sessionStorage.getItem('authState'));

const initialState = {
    token: 'Ayhw6#dDQVmzyz-wx679A$CgH7Lr-3zN$QhWPynW?*Gm$YjP!?d%fBZ*wm^N2Yu3',
    customer: null,
}
function authReducer(state, action){
    switch (action.type) {
        case 'reset': {
            sessionStorage.removeItem("authState")
            return initialState
        }
        case 'endOfSale': {
            return { 
                ...state,
                customer: null,
                user: null
            }
        }
        case 'customerAuth': {
            return {
                ...state,
                customer: action.payload
            }
        }
        default: {
            return state;
        }
    }
}

function AuthProvider({children}) {
    const [authState, authDispatch] = React.useReducer(authReducer, localState || initialState)

    React.useEffect(() => {
        sessionStorage.setItem('authState', JSON.stringify(authState))
    }, [authState])
    
    return (
            <AuthContext.Provider value={{authState, authDispatch}}>
                {children}
            </AuthContext.Provider>
    )
}

export { AuthProvider, AuthContext }