import React from 'react'
import { Link } from "react-router-dom";
import { AuthContext } from '../store/AuthContext'
import { BookContext } from '../store/BookContext'
import { useHistory } from 'react-router-dom';

export default function Home() {
  const { bookDispatch } = React.useContext(BookContext);
  const { authDispatch } = React.useContext(AuthContext);
  let history = useHistory();
  const start = () =>{
    bookDispatch({ type: 'reset'})
    authDispatch({ type: 'reset'})
    history.push('/cgu')
  }

    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center">
        <div className="w-5/6 flex flex-col justify-center text-justify mb-3 mt-3 sm:h-4/6 text-tiny sm:text-base">
            <p>
                Les livres que vous avez déjà lus encombrent vos étagères et vous avez besoin de place pour vos nouveautés ? Découvrez le service reprise de livres !
            </p>
            <br></br>
            <p>
            Ammareal vous permet de donner une seconde vie à tous les livres que vous n'utilisez plus. Sur cette plateforme, nous vous assurons la reprise de nombreux titres s'ils sont en bon état, et bénéficiez de bons d'achats utilisables directement en librairie sur tout le réseau {process.env.REACT_APP_NAME}. 
            </p>
            <br></br>
            <p>
            A <span className="font-bold text-greenMain">chaque livre rapporté</span>  en librairie vous faites un geste <span className="font-bold text-greenMain">solidaire et écologique</span> avec Ammareal :
            </p>

            <ul className="list-disc">
              <li className="ml-4"><span className="font-bold text-greenMain">Combat contre l’illettrisme :</span> Ammareal reverse un pourcentage sur la vente de chaque livre à des organisations caritatives françaises : 
              <div className="flex justify-around my-4 flex-wrap">

                <img 
                  src={`${process.env.PUBLIC_URL}/logos/BSF.png` } 
                  alt="logo BSF"
                  className="h-16 object-contain"
                  /> 
                  <img 
                  src={`${process.env.PUBLIC_URL}/logos/Lire-et-Sourire.png` } 
                  alt="logo Lire-et-Sourire"
                  className="h-16 object-contain"
                  /> 
                  <img 
                  src={`${process.env.PUBLIC_URL}/logos/mots-et-merveilles.png` } 
                  alt="logo mots-et-merveilles"
                  className="h-16 object-contain"
                  /> 
                  <img 
                  src={`${process.env.PUBLIC_URL}/logos/secours-populaire.png` } 
                  alt="logo lots-et-merveilles"
                  className="h-16 object-contain"
                  /> 
              </div>
              </li>
              <li className="ml-4"><span className="font-bold text-greenMain">Aide pour l’éducation :</span> Ammareal donne chaque année des milliers de livres à des écoles en France. </li>
              <li className="ml-4"><span className="font-bold text-greenMain">Ecologique :</span> Ammareal recycle les livres déchirés, millésimés… dans la filière française du papier. </li>
            </ul>
 
            
            
        </div>
        <div className="h-1/6 flex flex-col items-center justify-around">
        <button
          onClick={start}
          className="w-60 text-center px-12 py-3 rounded-md bg-greenMain hover:bg-greenHover text-white font-bold">
           Démarrer
        </button>

          
          <Link to="/infos/fonctionnement" className="text-indigo-600 hover:text-indigo-900 underline">Fonctionnement du service</Link>
          <Link to="/infos/livres" className="text-indigo-600 hover:text-indigo-900 underline">Les livres acceptés</Link>
        </div>
      </div>
    );
  }
  
  
  