import React from 'react';

import { BookContext } from '../store/BookContext'
import { AuthContext } from '../store/AuthContext'

import { IoPlaySkipBackSharp } from 'react-icons/io5';

export default function Reset() {
  const { bookDispatch } = React.useContext(BookContext);
  const { authDispatch } = React.useContext(AuthContext);

  const fullReset = () => {
    bookDispatch({ type: 'reset'})
    authDispatch({ type: 'reset'})
    window.location.replace('/');
  }; 

    return (
        <div className="absolute top-1 right-1">
            <IoPlaySkipBackSharp   
                className='m-2 text-cartMain hover:text-cartHover text-2xl cursor-pointer'
                onClick={() => fullReset()}
            />
        </div>
    );
}