import React from 'react';

const ConnectivityContext = React.createContext();

const initialState = {
   connectivity: true
}

function connectivityReducer(state, action){
    // console.log("state, action",state, action)
    return  {connectivity : action.payload }
}

function ConnectivityProvider({children}) {
    const [connectivityState, connectivityDispatch] = React.useReducer(connectivityReducer, initialState)

    React.useEffect(() => {
        sessionStorage.setItem('connectivityState', JSON.stringify(connectivityState))
    }, [connectivityState])

    return (
      <ConnectivityContext.Provider value={{connectivityState, connectivityDispatch}}>
          {children}
      </ConnectivityContext.Provider>
    )
}

export { ConnectivityProvider, ConnectivityContext }