import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { BookProvider } from './store/BookContext'
import { AuthProvider } from './store/AuthContext';
import { ConnectivityProvider } from './store/ConnectivityContext';


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConnectivityProvider>
        <BookProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BookProvider>
      </ConnectivityProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
