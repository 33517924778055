import Book from "../components/Book";

export default function BookPage() {
    return (
      <div className="w-screen h-screen">
            <Book />
      </div>
    );
  }
  
  
  