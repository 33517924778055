import Scan from "../components/Scan";
import Cart from "../components/Cart";

export default function ScanCartPage() {
    return (
      <div className="w-screen h-screen flex flex-col md:flex-row items-center justify-around">
        <div className="w-full md:w-7/12 h-3/4 ">
            <Scan/>
        </div>
        <div className="w-full md:w-5/12 h-3/4 ">
            <Cart checkout={false}/>
        </div>
      </div>
    );
  }
  
  
  