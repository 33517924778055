import React from 'react';
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";

import { AuthContext } from '../store/AuthContext'
import axios from "axios";

import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';


export default function ForgotPass() {
  const history = useHistory()
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [confPasswordShown, setConfPasswordShown] = React.useState(false);
  const [tempPasswordShown, setTempPasswordShown] = React.useState(false);

  const { authState, authDispatch } = React.useContext(AuthContext);
  const [ step, setStep ] = React.useState(1);
  const [ error, setError ] = React.useState('');

  const [ customer, setCustomer ] = React.useState({
      email: '',
      mdpTemp: '',
      mdpNew: '',
      mdpConf: ''
  });

  const [ queryRecover, setQueryRecover ] = React.useState('');
  const [ queryChange, setQueryChange ] = React.useState('');

  React.useEffect(() => {
      const sendTempPassword = () =>{

        axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/apiIframe/customer/reinitPassword/${process.env.REACT_APP_PARTNER_SLUG}`,
          headers: { Authorization: "Bearer " + authState.token },
          data: queryRecover
        })
        .then((res) => {
          if (!res.data || res.data.error !== 0) {
            console.log('forgot res', res.data)
            if(res.data.redirectToPresta){

              window.open("https://www.ammareal.fr/recuperation-mot-de-passe")
              history.push('/auth/login')

              
            }else{
              setError('Aucun compte correspondant à '+ customer.email +' n\'a été trouvé.')
              setCustomer({email: ''})
            }
 
          }else{
              setStep(2)
          }
        })
        .catch((err) => {
          setQueryRecover('')
          console.log("error",err);
        });

      }

      if (queryRecover !== '') {
          setError('')
          sendTempPassword();
          setQueryRecover('')
      }
  }, [queryRecover,customer.email, authDispatch, authState,history])


  React.useEffect(() => {
      const changePassword = () =>{


        axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/apiIframe/customer/changePassword`,
          headers: { Authorization: "Bearer " + authState.token },
          data: queryChange
        })
        .then((res) => {
          console.log(res)
          if (!res.data || res.data.error !== 0) {
            setError('Un problème est survenue lors du changement de votre mot de passe')
            
        }else{
            setQueryChange('')
            // setStep(1)
            console.log(res.data)
            authDispatch({type:'customerAuth', payload: res.data.customer})
            history.push('/checkout')
        }
        })
        .catch((err) => {
          setQueryChange('')
          console.log("error",err);
        });
      }
      if (queryChange !== '') {
          setError('')
          if(customer.mdpNew !== customer.mdpConf){
              setError('Les mots de passe ne correspondent pas')
              setQueryChange('')
          }else{
              changePassword();
              setQueryChange('')
          }  
      }
  }, [queryChange, authDispatch, authState, customer.mdpNew, customer.mdpConf, history])

  
return (
    
  <div className='px-6 h-3/4 flex justify-center items-center sm:w-3/5'>

  <div className=' w-4/5 flex-col justify-center items-center'>
    <div className='flex justify-start w-full mb-10'>
      <img alt='logo ammareal' src='../../logos/ammareal-text-prussian.svg'></img>
    </div> 
  {step === 1 ?
  <>
    <form 
    id="forgotForm"
      className='flex flex-col justify-evenly '
      onSubmit={e => {
          e.preventDefault();
          setQueryRecover(customer)
      }}>
      <div className=" flex flex-col items-center">
        
        <h3 className="text-2xl mb-5">Renseigner votre adresse mail pour recevoir un mot de passe temporaire</h3>
      </div>

      <div className=' flex flex-col justify-center py-6'>

        <div className="flex flex-col justify-evenly">
          <div className='mb-5 px-2'>
            <label className="block text-sm font-medium text-gray-700">
                Adresse mail
              </label>
              <input
                type="text"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="votre.adresse@email.fr"
                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                onChange={e => setCustomer({...customer, email: e.target.value.toLowerCase()}) }
                value={customer.email || ''}
                required
              />
          
          </div>

          
        </div>
      </div>
      {error !== '' ?  
      <span className="self-center text-cartHover">
        {error}
      </span>
      : null}
     
    </form>
     <div className='h-3/4 flex flex-col items-center'>
     <button 
       form="forgotForm"
       type='submit'
       className="sm:mt-16  w-60 text-center px-12 py-3 mb-2 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white"
     >Réinitialiser</button>
     <Link to="/checkout" className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover">Retour</Link>
   </div>
   </>


    :step === 2 ?

        

      <form 
            className=' flex flex-col justify-evenly'
            onSubmit={e => {
                e.preventDefault();
                setQueryChange(customer)
            }}>
            <div className="sm:h-1/6 flex flex-col justify-center">
              <h3 className="text-2xl">Changement de mot de passe</h3>
              <h2 className='mb-5'>Un mot de passe temporaire vient de vous être envoyé à votre adresse mail</h2> 
            </div>

            <div className='sm:h-4/6 flex flex-col justify-center'>

              <div className="flex flex-col justify-evenly">
                <div className='px-2'>
                  <label className="block text-sm font-medium text-gray-700">
                    Mot de passe temporaire
                    </label>


                    <div class="relative mt-1">
                    <input
                      type={tempPasswordShown ? "text" : "password"}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder="Mot de passe temporaire"
                      onChange={e => setCustomer({...customer, mdpTemp: e.target.value}) }
                      value={customer.mdpTemp || ''}
                      required
                    />
                      
                      <div 
                        class="flex absolute inset-y-0 right-2 items-center pl-3 cursor-pointer"
                        onClick={()=> setTempPasswordShown(!tempPasswordShown)}
                        >
                          {tempPasswordShown ? 
                            <FaEye className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                          :
                            <FaEyeSlash className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                          }
                      </div>                
                    </div>


                    
                
                </div>
                <div className='px-2 mt-4'>
                  <label className="block text-sm font-medium text-gray-700">
                    Nouveau mot de passe
                    </label>

                    <div class="relative mt-1">
                  
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Mot de passe"
                        onChange={e => setCustomer({...customer, mdpNew: e.target.value}) }
                        value={customer.mdpNew || ''}
                        required
                      />   
                      <div 
                        class="flex absolute inset-y-0 right-2 items-center pl-3 cursor-pointer"
                        onClick={()=> setPasswordShown(!passwordShown)}
                        >
                          {passwordShown ? 
                            <FaEye className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                          :
                            <FaEyeSlash className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                          }
                      </div>                
                    </div>
                    <small className="text-xs text-gray-500">Minimum 8 caractères, 1 majuscule et 1 chiffre</small>
                
                </div>
                <div className='px-2 mt-4'>
                  <label className="block text-sm font-medium text-gray-700">
                    Confirmation nouveau mot de passe
                    </label>

                    <div class="relative mt-1">
                  
                      <input
                        type={confPasswordShown ? "text" : "password"}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Nouveau mot de passe"
                        onChange={e => setCustomer({...customer, mdpConf: e.target.value}) }
                        value={customer.mdpConf || ''}
                        required
                      />   
                      <div 
                        class="flex absolute inset-y-0 right-2 items-center pl-3 cursor-pointer"
                        onClick={()=> setConfPasswordShown(!confPasswordShown)}
                        >
                          {confPasswordShown ? 
                            <FaEye className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                          :
                            <FaEyeSlash className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                          }
                      </div>                
                    </div>
                

                </div>
              </div>
            </div>
            {error !== '' ?
              <span className="self-center text-cartHover">
              {error} 
              </span>
            : null}
            <div className='h-2/6 mt-5 flex items-center justify-evenly'>
              <button 
                className="w-5/12 text-center py-3 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white"
                type='submit'
              >Réinitialiser</button>
              <button 
                className="w-5/12 text-center py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover text-sm sm:text-base"
                type='button'
                onClick={() => setQueryRecover(customer)}
              >Renvoyer le mail</button>
            </div>

          </form>
 
     : null }
    </div>
  
  </div>
  );
}