import { useParams } from "react-router-dom";

import Login from "../components/Login";
import SignUp from "../components/SignUp";
import ForgotPass from "../components/ForgotPass";
import UpdateInfo from '../components/UpdateInfo';

export default function AuthPage() {
    let { type } = useParams();
    console.log("type",type)
    return (
      <div className="w-screen flex items-center justify-center h-screen">
            { type === 'login' ?
                <Login />
            : type === 'signup'?
                <SignUp/>
            : type === 'forgotPass' ?
                <ForgotPass/>
            : type === "updateInfo"?
                <UpdateInfo/>
            : null }
      </div>
    );
  }
  
  
  