import { useParams } from "react-router-dom";
import { FaBan } from 'react-icons/fa';

import { Link } from "react-router-dom";

export default function InfosPage() {
    let { type } = useParams();
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center">
        <div className="w-4/6 h-4/6 flex flex-col justify-center text-justify">
            { type === 'fonctionnement' ?
                <>
                    <p>Nous rachetons vos livres à un prix spécifique à chaque livre.</p>
                    <p>{process.env.REACT_APP_CARDTYPE} Vous pouvez aussi faire le choix de simplement les donner.</p>
                    
                    <br></br>
                    <p>Les livres qui ne seront pas revendus seront donnés.</p>
                    
                    <p>Ceux qui ne seront pas donnés seront recyclés.</p>
                    <br></br>
                    <p>Plus d’informations sur <a href="https://www.ammareal.fr" className="text-indigo-600 hover:text-indigo-900 underline cursor-pointer">www.ammareal.fr</a></p>
                    
                </>
            : type === 'livres' ?

                <>
                    <p className="flex items-center text-2xl text-greenMain font-bold">
                    <img 
                        src={`${process.env.PUBLIC_URL}/logos/icon-pouce-vert.svg` } 
                        alt="icon pouce"
                        className="h-6 object-contain mr-2"
                        /> 
                        Nous reprenons</p>
                        <br></br>
                    <p>Livres de poche, grand format, bandes dessinées, livres d’art, livres parascolaires et cahiers de vacances (non remplis), ouvrages techniques et universitaires.</p>
                    <p>Nous reprenons les livres quelle que soit la langue.</p>
                    <p>Nous reprenons uniquement les livres en très bon état.</p>
                    <p>Les livres doivent avoir un code-barres.</p>
                    <br></br>

                    <p className="flex items-center text-2xl text-cartHover font-bold">
                    <FaBan className='text-cartHover text-2xl mr-2'/>
                    Nous ne reprenons pas</p>
                    <br></br>
                    <p>Les revues, les encyclopédies, les maisons d’éditions non vendues en librairie.</p>  
                    <p>Les livres abîmés (reliures cassées, livres tâchés, livres annotés…).</p>  
                    <p>Nous ne reprenons pas les livres sans code-barres.</p>  
                </>

            : null}
            
 
            
            
        </div>
        <div className="h-2/6 flex flex-col items-center justify-around">
          
          <Link 
            to="/"
            className="w-60 my-2 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover"
          >Retour</Link>
        </div>
      </div>
    );
  }
  
  
  