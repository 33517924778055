import React from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../store/AuthContext'

import { Link } from "react-router-dom";
import axios from "axios";

import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';


export default function Login() {
  const history = useHistory()
  const { authState, authDispatch } = React.useContext(AuthContext);
  const [ error, setError ] = React.useState('');
  const [ query, setQuery ] = React.useState('');
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [ customer, setCustomer ] = React.useState({
    username: '',
    password: ''
  });

  React.useEffect(() => {
    const loginCustomer = () =>{

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/apiIframe/customer/login`,
        headers: { Authorization: "Bearer " + authState.token },
        data: query
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.status && !res.data.toBeUpdated) {
          authDispatch({type:'customerAuth', payload: res.data.user});
          history.push('/checkout')
        }else if (res.data.status && res.data.toBeUpdated) {
          authDispatch({type:'customerAuth', payload: res.data.user});
          history.push('/auth/updateInfo')
        }else{
            setError('Identifiant ou mot de passe invalide');
        }
      })
      .catch((err) => {
        setQuery('')
        console.log("error",err);
      });

    }

    if (query !== '') {
      setError('')
      setQuery('')
      loginCustomer();
    }
}, [query, authDispatch, authState, history])

  return (
    <div className="h-screen  px-6 flex flex-col justify-evenly items-center sm:w-3/5">
      <div className="h-1/2 flex flex-col justify-evenly w-full items-center">
        <div className='flex justify-start w-4/5 mb-10'>
          <img alt='logo ammareal' src='../../logos/ammareal-text-prussian.svg'></img>
        </div>
        <h3 className="text-2xl text-center">Identification</h3>

          <form 
            className="w-4/5" 
            id='loginForm'
            onSubmit={e => {
              e.preventDefault();
              setQuery(customer)
          }}>
            <div className="py-5 flex flex-col">
              <div className='pt-3'>
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Adresse mail
                </label>
                <input
                  type="text"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  placeholder="Email"
                  onChange={e => setCustomer({...customer, username: e.target.value.toLowerCase()}) }
                  value={customer.username || ''}
                  required
                />
              </div>

              <div className='pt-3'>
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Mot de passe
                </label>
                
                
                <div className="relative mt-1">
                  
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    placeholder="Mot de passe"
                    onChange={e => setCustomer({...customer, password: e.target.value}) }
                    value={customer.password || ''}
                    required
                  />   
                  <div 
                    className="flex absolute inset-y-0 right-2 items-center pl-3 cursor-pointer"
                    onClick={()=> setPasswordShown(!passwordShown)}
                    >
                      {!passwordShown ? 
                        <FaEye className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                      :
                        <FaEyeSlash className='text-gray-500 hover:text-gray-800 text-2xl cursor-pointer'/>
                      }
                  </div>                
                </div>
              </div>
              <div className="pt-3 self-end">
                <Link 
                  to="/auth/forgotPass" 
                  className="text-indigo-600 hover:text-indigo-900 underline">
                    Mot de passe oublié
                </Link>
              </div>
            </div>
          </form>
          {error !== '' ?  
            <span className="h-[24px] self-center text-cartHover">
              {error}
            </span>
          : null}
        <button form='loginForm' className="w-60 text-center px-12 py-3 mb-2 rounded-md font-bold bg-greenMain hover:bg-greenHover text-white">Se connecter</button>
        <Link to="/checkout" className="w-60 text-center px-12 py-3 rounded-md font-bold bg-greyMain hover:bg-greyHover">Retour</Link>
      </div>
    </div>
  );
}