import React from 'react';
import { AuthContext } from '../store/AuthContext'
import { BookContext } from '../store/BookContext'

import axios from "axios";
import fileDownload from 'js-file-download'

export default function PreRefurb() {
  const { bookState, bookDispatch } = React.useContext(BookContext);
  const { authState } = React.useContext(AuthContext);
  const [ callCreate, setCallCreate ] = React.useState(true);
  const [ callGenerate, setCallGenerate ] = React.useState(false);
  const [ error, setError ] = React.useState('');
  const [ loading, setLoading ] = React.useState(false);

  React.useEffect(() => {
    const createPreRefurb = () =>{

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/apiIframe/preRefurb/create`,
        headers: { Authorization: "Bearer " + authState.token },
        data: {
          "customer": authState.customer._id,
          "books": bookState.cart,
        }
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.status) {
          bookDispatch({type:'preRefurb', payload: res.data.preRefurb});
          setCallGenerate(true)
        }else{
            setError('Erreur de generation du document');
        }
      })
      .catch((err) => {
        console.log("error",err);
      });

    }

    if (callCreate === true && bookState.preRefurb === null) {
      setCallCreate(false)
      setError('')
      createPreRefurb();
    }
}, [callCreate, bookDispatch, authState, bookState.cart, bookState.preRefurb])

React.useEffect(() => {
  const pdfPreRefurb = () =>{

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/apiIframe/pdf/${process.env.REACT_APP_PARTNER_SLUG}`,
      headers: { Authorization: "Bearer " + authState.token },
      data: {
        "preRefurbId": bookState.preRefurb._id,
      },
      responseType: 'blob',
    })
    .then((res) => {
      // console.log(res.data)
      if (res.data) {
        fileDownload(res.data, 'bon.pdf');
        setLoading(false);
        // bookDispatch({ type: 'reset'})
      }else{
          setError('Erreur de generation du document');
      }
    })
    .catch((err) => {
      setError('Une erreur est survenue. Si le problème persiste, merci de nous contacter.');
      console.log("error",err);
    });

  }

  if (callGenerate === true && bookState.preRefurb !== null) {
    setCallGenerate(false)
    setLoading(true)
    setError('')
    pdfPreRefurb();
  }
}, [callGenerate, bookDispatch, authState, bookState.preRefurb])

  return (
    <div className="h-full px-6 flex flex-col justify-evenly items-center ">
      { !error ?
        <>
          <h3 className="text-base sm:text-2xl">Félicitations, votre enregistrement est terminé !</h3>
            <h3 className="text-base sm:text-2xl text-justify">Téléchargez votre document et rendez-vous dans votre librairie favorite pour faire reprendre vos livres et bénéficier d'un bon d'achat.</h3>
          {loading ?
          <>
            <h3 className="text-base sm:text-2xl ">Génération du document</h3>
            <div className="w-16 h-16 border-4 border-greenMain border-solid border-t-transparent rounded-full animate-spin"></div>
          </>
          :
          <>
            <h3 className="text-base sm:text-2xl">Un mail avec ce document vient également de vous être envoyé.</h3>
            <button 
              className="w-60 text-center px-12 py-3 rounded-md font-medium bg-greenMain hover:bg-greenHover text-white mt-2"
              onClick={e => setCallGenerate(true)}
            >Télécharger</button>
          </>
          }
        </>
      : null}
      {error !== '' ?
        <span className="h-[24px] self-center text-cartHover">
          error
        </span>
       : null}
      {error !== '' ? 
        <button 
          className="w-60 text-center px-12 py-3 rounded-md font-medium bg-greenMain hover:bg-greenHover text-white"
          onClick={e => setCallCreate(true)}
        >Réessayer</button> 
      : null}
      
    </div>
  );
}